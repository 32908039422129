/* global tumarket */

import * as $ from "jquery";
import { openProgressbar, closeProgressbar } from "./progressbar";

/**
 * @typedef {object} Lib
 * @property {any} [conditionObject]
 * @property {string} [cssLink]
 * @property {string} [jsLink]
 * @property {boolean} [showProgressbar=true]
 */

/**
 * 
 * @param {Lib} param0 
 * @returns 
 */
function loadLib({ jsLink, cssLink, conditionObject, showProgressbar = true, useVersion = true }) {
    if (cssLink) {
        if (useVersion) {
            cssLink = `${cssLink}?v=${tumarket.cacheDate || ''}`;
        }

        if (!document.querySelector(`link[rel="stylesheet"][href="${cssLink}"]`)) {
            $('head').append(`<link href="${cssLink}" rel="stylesheet" />`);
        }

        return Promise.resolve();
    }

    if (!conditionObject) {
        if (showProgressbar) openProgressbar();
        
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            document.body.appendChild(script);
            script.onload = resolve;
            script.onerror = reject;
            script.async = true;
            script.src = jsLink;
        }).then(() => {
            if (showProgressbar) closeProgressbar();
        });
    }

    return Promise.resolve();
}

export { loadLib };
