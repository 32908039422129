import * as $ from "jquery";

function openProgressbar() {
    if (!$('.as-progCont').length){
        $('body').append("<div class='as-progCont'></div>");
    }

    $('.as-progCont').html('<div class="as-overlay"><div class="loader">Подождите...</div></div>');
}

function closeProgressbar() {
    $('.as-overlay').fadeOut('slow', function () {
        $(this).remove();
    });
}

export { openProgressbar, closeProgressbar };
