let _version = "";
let _domain = "";

/**
 * @typedef {object} CookieOptions
 * @property {string} version
 * @property {string} domain
 */

/**
 * 
 * @param {CookieOptions} param0 
 */
function initCookies({ version, domain }) {
    _version = version;
    _domain = domain;
}

/**
 * 
 * @param {string} name 
 * @param {string} value 
 * @param {number} [days] 
 */
function setCookie(name, value, days = 30) {
    if (_version) {
        name = name + "_" + _version;
    }

    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    } else {
        expires = "";
    }

    var domain = "";
    if (_domain) {
        var domainName = _domain.startsWith(".") ? _domain.substring(1) : _domain;

        if (!location.hostname.includes(domainName)) {
            _domain = location.hostname;
        }

        if (location.href.toLowerCase().indexOf(domainName.toLowerCase()) > -1) {
            domain = "; domain=" + _domain;
        }
    }

    document.cookie = name + "=" + encodeURIComponent(value) + expires + domain + "; path=/";
}

/**
 * 
 * @param {string} c_name 
 * @returns {string}
 */
function getCookie(c_name) {
    if (_version) {
        c_name = c_name + "_" + _version;
    }

    if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            var c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = document.cookie.length;
            }
            return decodeURIComponent(document.cookie.substring(c_start, c_end));
        }
    }

    return "";
}

window.getCookie = getCookie;
window.setCookie = setCookie;

export { getCookie, setCookie, initCookies };