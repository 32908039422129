import { toggleClass } from "./dom";

/**
 * 
 * @param {HTMLElement} element 
 * @param {boolean} value
 */
function setToggle(element, value) {
    toggleClass(element, 'fa-toggle-on', value);
    toggleClass(element, 'fa-toggle-off', value == undefined ? undefined : !value);
    element.dispatchEvent(new Event("tum.toggle.change", { bubbles: true }));
}

export { setToggle };
